import { createApp } from 'vue'
import App from './App'
import router from "@/router";
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
// import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'


const vuetify= createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
    //
  }
})


createApp(App)
    .use(router)
    .use(vuetify)
    .mount('#app');