import {createRouter, createWebHistory} from "vue-router"
import NotFoundPage from "@/pages/NotFoundPage";
import HomePage from "@/pages/HomePage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import AllCasesPage from "@/pages/AllCasesPage.vue";
import CasePage from "@/pages/CasePage.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/about",
        name: "About",
        component: AboutPage,
    },
    {
        path: "/allCases",
        name: "AllCases",
        component: AllCasesPage,
    },
    {
        path: "/case",
        name: "Case",
        component: CasePage,
    },
    {
        path: "/:pathMatch(.*)*",
        component: NotFoundPage,
    },

]

const router = createRouter({
    routes,
    history: createWebHistory()
    })

export default router;
