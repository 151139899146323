<template>
  <v-app>
    <v-app-bar class="overlay" elevation="1">
      <div class="mx-auto" v-if="!isMobile()">
        <v-row align="center" class="bg-transparent">
          <v-spacer></v-spacer>
          <v-col cols="auto" v-for="i in navbar" :key="i">
            <div v-if="i.id !== 1">
              <a :href="i.href">
                <v-btn rounded variant="plain" color="white">
                  {{ i.title }}
                </v-btn>
              </a>
            </div>
            <div v-else>
              <a :href="i.href">
                <v-btn rounded variant="outlined" color="white">
                  {{ i.title }}
                </v-btn>
              </a>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <div class="bg-transparent mx-auto" v-else>
        <v-btn
            @click="showNavDrawer"
            rounded="xl"
            color="white"
            variant="tonal"
        >
          <label v-if="!drawer">
            Открыть меню
          </label>
          <label v-else>
            Закрыть меню
          </label>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
        class="overlay"
        v-model="drawer"
        location="left"
        temporary
    >
      <v-row justify="start">
        <v-col cols="9" v-for="i in navbar" :key="i">
          <div v-if="i.id !== 2">
            <a :href="i.href">
              <v-btn rounded color="white" variant="text">
                {{ i.title }}
              </v-btn>
            </a>
          </div>
          <div v-else>
            <a :href="i.href">
              <v-btn rounded variant="tonal" color="deep-purple-accent-2">
                {{ i.title }}
              </v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-sheet width="100%" height="100px" color="black">
    </v-sheet>

    <v-card class="case-gradient rounded-b-0" elevation="0">
      <v-container>
        <v-card-item class="text-lg-left text-center">
          <v-row justify="start" align="center">
            <v-col cols="auto">
              <label class="text-h3 text-lg-h2"><b>Все кейсы</b></label>
            </v-col>
          </v-row>
        </v-card-item>
        <br>
        <v-row>
          <v-col class="v-col-12 v-col-lg-4" v-for="c in cases" :key="c">
            <v-card
                @click="alert('f')"
                @mouseover="c.show_icon = true"
                @mouseleave="c.show_icon = false"
                rounded="xl"
                variant="flat"
                :color="c.color"
            >
              <v-card-item>
                <v-card class="bg-transparent" variant="flat">
                  <v-card-item>
                    <v-row>
                      <v-col cols="10" class="text-white">
                        <b>{{ c.title }}</b>
                        <br>
                        <label>{{ c.desc }}</label>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" v-if="c.show_icon">
                        <v-icon size="small">
                          mdi-open-in-new
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-item>
                </v-card>
              </v-card-item>
              <div>
                <v-img class="mx-auto" width="70%" :cover=true :src="c.img">
                </v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "CasePage",
  data: () => ({
    drawer: false,
    group: null,
    menu_btn_loading: false,
    hidden_email: true,
    dialog: false,
    navbar: [
      {
        id: 0,
        title: "Кейсы",
        icon: "mdi-briefcase-outline",
        href: "/#cases",
      },
      {
        id: 1,
        title: "Заказать",
        icon: "mdi-clipboard-check-outline",
        href: "/#order",
      },
      {
        id: 2,
        title: "Технологии",
        icon: "mdi-animation",
        href: "/#tech",
      },
      {
        id: 3,
        title: "Контакты",
        icon: "mdi-account-box-outline",
        href: "/#contact"
      },
    ],
    cases: [
      {
        title: '@JourneysBuilderBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'buzz',
        img: "./nick_1.webp",
        color: "blue-grey-darken-4",

        show_icon: false
      },
      {
        title: '@StarfallEventBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'bar',
        img: "./starfall_3.webp",
        color: "blue-accent-2",
        show_icon: false
      },
      {
        title: '@GentlyDropBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'Таким образом, постоянный количественный рост и сфера нашей активности предполагает независимые способы реализации своевременного выполнения сверхзадачи. Как принято считать, ключевые особенности структуры проекта призывают нас к новым свершениям.',
        img: "./gently_drop_1.webp",
        color: "deep-purple-accent-1",
        show_icon: false
      },
      {
        title: '@TTPapaBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'buzz',
        img: "https://ton.org/images/home-page/app-cards/getgems.webp",
        color: "green",
        show_icon: false
      },
      {
        title: '@AirticketsBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'buzz',
        img: "https://ton.org/images/home-page/app-cards/getgems.webp",
        color: "grey-darken-4",
        show_icon: false
      },
      {
        title: '@CardDurakBot',
        desc: "Shop with sneakers in the form of a web app",
        value: 'buzz',
        img: "https://ton.org/images/home-page/app-cards/getgems.webp",
        color: "amber-darken-1",
        show_icon: false
      },
      {
        title: 'чатгптбот.рф',
        desc: "Shop with sneakers in the form of a web app",
        value: 'buzz',
        img: "https://ton.org/images/home-page/app-cards/getgems.webp",
        color: "indigo-accent-3",
        show_icon: false
      },
    ],
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    showNavDrawer() {
      this.drawer = !this.drawer
    },
  }
}
</script>


<style scoped>

.overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(5px) !important;
}

.case-card-color {
  background-color: rgba(0, 0, 0, 0.17) !important;
  backdrop-filter: blur(5px) !important;
}

.tech-card-color {
  background-color: rgba(0, 0, 0, 0.40) !important;
  backdrop-filter: blur(5px) !important;
}

.title-gradient {
  background: linear-gradient(90deg, #300EFF 25%, #02F38D 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-gradient {
  background: linear-gradient(to top, #000000, #4F12FD 100%, #000000);
}

.glow {
  text-shadow: 0 0 50px rgba(2, 243, 141, 0.30);
}


.case-gradient {
  background: black;
}

.smooth-border {
  border-width: 1px;
  border-style: solid;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background:
      linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.55),
          rgba(255, 255, 255, 0.25),
          rgba(255, 255, 255, 0.55),
          rgba(0, 0, 0, 0.55)
      ) 1 1 1 1;

}

.pre-subtitle {
  white-space: pre-line;
  padding-bottom: 10px;
}

</style>