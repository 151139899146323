<template>
  <v-app>
    <v-app-bar class="overlay" elevation="1">
      <div class="mx-auto" v-if="!isMobile()">
        <v-row align="center" class="bg-transparent">
          <v-spacer></v-spacer>
          <v-col cols="auto" v-for="i in navbar" :key="i">
            <div v-if="i.id !== 1">
              <a :href="i.href">
                <v-btn rounded variant="plain" color="white">
                  {{ i.title }}
                </v-btn>
              </a>
            </div>
            <div v-else>
              <a :href="i.href">
                <v-btn rounded variant="outlined" color="white">
                  {{ i.title }}
                </v-btn>
              </a>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <div class="bg-transparent mx-auto" v-else>
        <v-btn
            @click="showNavDrawer"
            rounded="xl"
            color="white"
            variant="tonal"
        >
          <label v-if="!drawer">
            Открыть меню
          </label>
          <label v-else>
            Закрыть меню
          </label>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
        class="overlay"
        v-model="drawer"
        location="left"
        temporary
    >
      <v-row justify="start">
        <v-col cols="9" v-for="i in navbar" :key="i">
          <div v-if="i.id !== 1">
            <a :href="i.href">
              <v-btn rounded color="white" variant="text">
                {{ i.title }}
              </v-btn>
            </a>
          </div>
          <div v-else>
            <a :href="i.href">
              <v-btn rounded variant="tonal" color="deep-purple-accent-2">
                {{ i.title }}
              </v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-sheet width="100%" height="100px" color="black">
    </v-sheet>

  <v-card class="case-gradient rounded-b-0" elevation="0">
    <v-container>
      <v-card-item class="text-lg-left text-center">
        <v-row justify="start" align="center">
          <v-col cols="auto">
            <label class="text-h3 text-lg-h2"><b>Все кейсы</b></label>
          </v-col>
        </v-row>
      </v-card-item>
      <br>
      <v-row>
        <v-col class="v-col-12 v-col-lg-4" v-for="c in cases" :key="c">
          <a :href="c.url" class="text-decoration-none">
          <v-card
              @click="emptyFunc"
              @mouseover="c.show_icon = true"
              @mouseleave="c.show_icon = false"
              rounded="xl"
              variant="flat"
              :color="c.color"
          >
            <v-card-item>
              <v-card class="bg-transparent" variant="flat">
                <v-card-item>
                  <v-row align="center" justify="start" no-gutters="">
                    <v-col cols="10">
                      <v-chip color="white" size="small">{{ c.type }}</v-chip>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" v-if="c.show_icon">
                      <v-icon size="small" color="white">
                        mdi-open-in-new
                      </v-icon>
                    </v-col>
                    <v-col cols="10" class="text-white mt-2 pl-1">
                      <b>{{ c.title }}</b>
                      <br>
                      <v-card-subtitle class="pre-subtitle">{{ c.desc }}</v-card-subtitle>
                    </v-col>

                  </v-row>
                </v-card-item>
              </v-card>
            </v-card-item>
            <div>
              <v-img class="mx-auto" :src="c.img">
              </v-img>
            </div>
          </v-card>
          </a>
        </v-col>
      </v-row>

    </v-container>
  </v-card>
    <v-card color="black">
      <v-container>
        <v-card-item>
          <label class="text-h3 text-lg-h2"><b>Контакты</b></label>
        </v-card-item>
        <br>
        <v-row class="text-left">
          <v-col cols="auto">
            <v-card-item>
              E-mail: {{ email }}
              <br>
              Telegram канал: <a href="https://t.me/SmithTeamCase">https://t.me/SmithTeamCase</a>
            </v-card-item>
          </v-col>
          <v-col cols="auto">
            <v-card-item>
              Telegram (Вадим): <a href="https://t.me/ITsmiths">https://t.me/ITsmiths</a>
              <br>
              Telegram (Дмитрий): <a href="https://t.me/mainbatya">https://t.me/mainbatya</a>
            </v-card-item>
          </v-col>
          <v-col cols="auto">
            <v-card-item>
              GitHub (Дмитрий): <a href="https://github.com/roomdie">https://github.com/roomdie</a>
              <br>
              GitHub (Вадим): <a href="https://github.com/smithreactiven">https://github.com/smithreactiven</a>
            </v-card-item>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col cols="12">
            <v-card color="black" class="text-center">
              <v-card-subtitle>© 2024 Smith Team. All Rights Reserved.</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "AllCasesPage",
  data: () => ({
    drawer: false,
    group: null,
    email: "blsmithit@gmail.com",
    menu_btn_loading: false,
    hidden_email: true,
    dialog: false,
    navbar: [
      {
        id: 0,
        title: "Кейсы",
        icon: "mdi-briefcase-outline",
        href: "/#cases",
      },
      {
        id: 1,
        title: "Заказать",
        icon: "mdi-clipboard-check-outline",
        href: "/#order",
      },
      {
        id: 2,
        title: "Технологии",
        icon: "mdi-animation",
        href: "/#tech",
      },
      {
        id: 3,
        title: "Контакты",
        icon: "mdi-account-box-outline",
        href: "/#contact"
      },
    ],
    cases: [
      {
        title: '@JourneysBuilderBot',
        type: "Веб приложение",
        desc: "Создавайте собственные конкурсы, привлекая новую аудиторию.",
        url: 'https://t.me/JourneysBuilderBot',
        img: "./nick_1.webp",
        color: "deep-purple-accent-1",
        show_icon: false
      },
      {
        title: '@StarfallEventBot',
        type: "Веб приложение",
        desc: "Организовывайте мероприятия или принимайте участие в них.",
        url: 'https://t.me/StarfallEventBot',
        img: "./starfall_3.webp",
        color: "blue-accent-2",
        show_icon: false
      },
      {
        title: '@GentlyDropBot',
        type: "Веб приложение",
        desc: "Магазин для покупки кроссовок, одежды и аксессуаров.",
        url: 'https://t.me/GentlyDropBot',
        img: "./gently_drop_1.webp",
        color: "blue-grey-darken-4",
        show_icon: false
      },
      {
        title: '@TTPapaBot',
        type: "Бот",
        desc: "Скачивайте YouTube, TikTok и Instagram через Telegram.",
        url: 'https://t.me/TTPapaBot',
        img: "./papa-bot.webp",
        color: "green",
        show_icon: false
      },
      {
        title: '@AirticketsWebAppBot',
        type: "Веб приложение",
        desc: "Веб приложение для конкурса от Telegram Contests (@contest).",
        url: 'https://t.me/AirticketsWebAppBot',
        img: "./airtickets.webp",
        color: "grey-darken-4",
        show_icon: false
      },
      {
        title: '@CardDurakBot',
        type: "Бот",
        desc: "Играйте в карты в чатах Telegram.",
        url: 'https://t.me/CardDurakBot',
        img: "./durak.webp",
        color: "amber-darken-3",
        show_icon: false
      },
      {
        title: 'чатгптбот.рф',
        type: "Сайт",
        desc: "Сайт визитка для Telegram бота.",
        url: 'https://чатгптбот.рф',
        img: "./gpt.webp",
        color: "indigo-accent-3",
        show_icon: false
      },
    ],
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    showNavDrawer() {
      this.drawer = !this.drawer
    },
    emptyFunc() {

    }
  }
}
</script>


<style scoped>

.overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(5px) !important;
}

.case-card-color {
  background-color: rgba(0, 0, 0, 0.17) !important;
  backdrop-filter: blur(5px) !important;
}

.tech-card-color {
  background-color: rgba(0, 0, 0, 0.40) !important;
  backdrop-filter: blur(5px) !important;
}

.title-gradient {
  background: linear-gradient(90deg, #300EFF 25%, #02F38D 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-gradient {
  background: linear-gradient(to top, #000000, #4F12FD 100%, #000000);
}

.glow {
  text-shadow: 0 0 50px rgba(2, 243, 141, 0.30);
}


.case-gradient {
  background: black;
}

.smooth-border {
  border-width: 1px;
  border-style: solid;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background:
      linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.55),
          rgba(255, 255, 255, 0.25),
          rgba(255, 255, 255, 0.55),
          rgba(0, 0, 0, 0.55)
      ) 1 1 1 1;

}

.pre-subtitle {
  white-space: pre-line;
  padding-bottom: 10px;
}

</style>