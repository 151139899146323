<template>
  <v-container>
    <v-card class="text-center" elevation="0">
      <v-card-item>
        <v-img height="200" src="./duck-confusion.webp"></v-img>
      </v-card-item>
      <v-card-title>
        Кажется что-то пошло не так!
      </v-card-title>
      <v-card-text>
        Браузеру не удалось обнаружить на сервере указанный URL.
        <br>
        Наш сервис доступен только в Telegram.
      </v-card-text>
      <v-card-item>
        <a href="https://t.me/StarfallEventBot">
          <v-btn
              variant="tonal"
              color="blue"
              width="30%"
          >
            Перейти
          </v-btn>
        </a>
      </v-card-item>
    </v-card>
  </v-container>

</template>

<script>
export default {
  name: "AboutPage",

}
</script>

<style scoped>

</style>