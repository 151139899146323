<template>
  <v-app>
    <v-app-bar class="overlay" elevation="1">
      <div class="mx-auto" v-if="!isMobile()">
        <v-row align="center" class="bg-transparent">
          <v-spacer></v-spacer>
          <v-col cols="auto" v-for="i in navbar" :key="i">
            <div v-if="i.id !== 1">
              <a :href="i.href">
              <v-btn rounded variant="plain" color="white">
                {{ i.title }}
              </v-btn>
              </a>
            </div>
            <div v-else>
              <a :href="i.href">
              <v-btn rounded variant="outlined" color="white">
                {{ i.title }}
              </v-btn>
              </a>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <div class="bg-transparent mx-auto" v-else>
        <v-btn
          @click="showNavDrawer"
          rounded="xl"
          color="white"
          variant="tonal"
        >
          <label v-if="!drawer">
            Открыть меню
          </label>
          <label v-else>
            Закрыть меню
          </label>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
        class="overlay"
        v-model="drawer"
        location="left"
        temporary
    >
      <v-row justify="start">
        <v-col cols="9" v-for="i in navbar" :key="i">
          <div v-if="i.id !== 1">
            <a :href="i.href">
              <v-btn rounded color="white" variant="text">
                {{ i.title }}
              </v-btn>
            </a>
          </div>
          <div v-else>
            <a :href="i.href">
            <v-btn rounded variant="tonal" color="deep-purple-accent-2">
              {{ i.title }}
            </v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-sheet width="100%" height="100px" color="black">
    </v-sheet>
    <v-card color="black" class="rounded-b-0">

    <v-container>
    <v-row class="bg-black justify-lg-start justify-center" justify="start" align="center">
      <v-col class="v-col-12 v-col-lg-6">
        <br>
        <v-card color="black" class="text-lg-left text-center mt-14" elevation="0">
          <v-card-item class="title-gradient">
            <label class="text-h3 text-lg-h1 glow"><b>Smith Team</b></label>
          </v-card-item>
          <v-card-item>
            Команда разработчиков из Москвы, специализирующаяся на создании высококачественных веб-приложений, ботов и сайтов под ключ.

            Мы предлагаем полный цикл разработки, начиная от идеи и дизайна, до реализации и поддержки проекта.
          </v-card-item>
          <v-card-item>
            <v-row>
              <v-col v-if="!isMobile()" class="v-col-lg-auto">
                <v-btn
                    rounded="xl"
                    color="#4F12FD"
                    width="100%"
                >
                  <a href="#order" class="text-decoration-none text-white" >Сделать заказ</a>
                </v-btn>
              </v-col>
<!--              <v-col class="v-col-lg-auto v-col-12">-->
<!--                <v-btn-->
<!--                    width="100%"-->
<!--                    rounded="xl"-->
<!--                    color="white"-->
<!--                    variant="outlined"-->
<!--                    append-icon="mdi-chevron-right"-->
<!--                >-->
<!--                  Подробнее-->
<!--                </v-btn>-->
<!--              </v-col>-->
              <v-col v-if="isMobile()" class="v-col-lg-auto v-col-12">
                <v-btn
                    rounded="xl"
                    color="#4F12FD"
                    width="100%"
                >
                  <a href="#order" class="text-decoration-none text-white" >Сделать заказ</a>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-item>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="v-col-lg-2 v-col-6 mb-lg-15 mt-15">
        <v-img class="mx-auto" width="90%"  src="./vadim_avatar.png">
        </v-img>
        <v-card width="100%" rounded="lg" class="bg-transparent mx-auto text-center">
          <v-card-item>
            <label class="text-white">Вадим, 22 года</label>
            <v-sheet class="bg-transparent" height="5px"></v-sheet>
            <div v-for="p in vadim_positions"
                 :key="p">
              <v-chip
                  :color="p.color"
                  :text="p.text"
                  density="comfortable"
                  class="px-2 py-2"
              ></v-chip>
              <v-sheet class="bg-transparent" height="5px"></v-sheet>
            </div>
            <v-sheet class="bg-transparent" height="15px"></v-sheet>
            <a href="https://t.me/ITsmiths">
              <v-btn color="#4F12FD" variant="flat" rounded="lg" append-icon="mdi-open-in-new">
                Telegram
              </v-btn>
            </a>
          </v-card-item>
        </v-card>
      </v-col>

      <v-col class="v-col-lg-3 v-col-6 mt-lg-15">
        <v-img class="mx-auto mt-lg-15" width="90%" src="./dmitriy_avatar.png">
        </v-img>
        <v-card width="100%" rounded="lg"  class="bg-transparent mx-auto text-center">
          <v-card-item>
            <label class="text-white">Дмитрий, 20 лет</label>
            <v-sheet class="bg-transparent" height="5px"></v-sheet>
            <div v-for="p in dmitriy_positions"
                 :key="p">
              <v-chip
                  :color="p.color"
                  :text="p.text"
                  density="comfortable"
                  class="px-2 py-2"
              ></v-chip>
              <v-sheet class="bg-transparent" height="5px"></v-sheet>
            </div>
            <v-sheet class="bg-transparent" height="15px"></v-sheet>
            <a href="https://t.me/mainbatya">
              <v-btn color="#4F12FD" variant="flat" rounded="lg" append-icon="mdi-open-in-new">
                Telegram
              </v-btn>
            </a>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>

    </v-container>
    </v-card>
    <v-sheet width="100%" height="100px" color="black" id="cases">
    </v-sheet>

    <v-card class="case-gradient rounded-b-0" elevation="0">
      <v-container>
        <v-card-item class="text-lg-left text-center">
          <v-row justify="start" align="center">
            <v-col cols="auto">
              <label class="text-h3 text-lg-h2"><b>Кейсы</b></label>
            </v-col>
            <v-col cols="auto" class="pt-8">
              <v-btn
                  @click="moveToCasesPage"
                  size="small"
                  rounded="xl"
                  color="white"
                  variant="outlined"
                  append-icon="mdi-chevron-right"
              >
                Все
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-subtitle class="pre-subtitle">
          Мы гордимся нашим портфолио проектов, которые мы успешно реализовали для различных клиентов. Каждый из наших кейсов является уникальным опытом и профессионального подхода к разработке.
        </v-card-subtitle>
        <br>
        <v-row>
          <v-col class="v-col-12 v-col-lg-4" v-for="c in cases" :key="c">
            <a :href="c.url" class="text-decoration-none">
              <v-card
                  @click="emptyFunc"
                  @mouseover="c.show_icon = true"
                  @mouseleave="c.show_icon = false"
                  rounded="xl"
                  variant="flat"
                  :color="c.color"
                  height="550px"
              >
                <v-card-item>
                  <v-card class="bg-transparent" variant="flat">
                    <v-card-item>
                      <v-row align="center" justify="start" no-gutters="">
                        <v-col cols="10">
                          <v-chip color="white" size="small">{{ c.type }}</v-chip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" v-if="c.show_icon">
                          <v-icon size="small">
                            mdi-open-in-new
                          </v-icon>
                        </v-col>
                        <v-col cols="10" class="text-white mt-2 pl-1">
                          <b>{{ c.title }}</b>
                          <br>
                          <v-card-subtitle class="pre-subtitle">{{ c.desc }}</v-card-subtitle>
                        </v-col>

                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-card-item>
                <div>
                  <v-carousel
                      :interval=c.interval
                      :cycle="true"
                      :show-arrows="false"
                      :hide-delimiter-background="true"
                  >
                    <v-carousel-item
                        v-for="(slide, i) in c.slides"
                        :key="i"
                        :src="slide"
                        cover="true"
                    >
                    </v-carousel-item>
                  </v-carousel>
<!--                  <v-img class="mx-auto" :cover="true" :src="c.img">-->
<!--                  </v-img>-->
                </div>
              </v-card>
            </a>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="v-col-auto">
            <v-btn
                @click="moveToCasesPage"
                variant="text"
                color="white"
                rounded="xl"
            >
              Еще кейсы...
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-if="!isMobile()" elevation="0" class="tech-gradient rounded-t-0" id="tech">
      <v-container>
        <v-card-item>
          <label class="text-h3 text-lg-h2"><b>Технологии</b></label>
        </v-card-item>

        <v-row>
          <v-col v-for="i in techs" :key="i" class="v-col-6 v-col-lg-4">
            <v-card height="250px" elevation="0" class="tech-card-color overflow-auto" rounded="lg">
              <v-card-title>
                <v-icon size="small">
                  {{ i.icon }}
                </v-icon>
                <br>
                {{ i.title }}
              </v-card-title>
              <v-card-subtitle class="pre-subtitle">
                {{ i.desc }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-else elevation="0" class="tech-gradient rounded-t-0" id="tech">
        <v-card-item>
          <label class="text-h3 text-lg-h2"><b>Технологии</b></label>
        </v-card-item>

        <v-carousel cycle="white" interval="3000" color="white" hide-delimiters="" progress="white" :show-arrows="false">
          <template v-slot:prev="{ props }">
            <v-btn
                size="small"
                width="100px"
                class="rounded-lg mt-15"
                icon="mdi-chevron-triple-left"
                color="white"
                variant="tonal"
                @click="props.onClick"
            ></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
                size="small"
                width="100px"
                class="rounded-lg mt-15"
                icon="mdi-chevron-triple-right"
                color="white"
                variant="tonal"
                @click="props.onClick"
            ></v-btn>
          </template>
          <v-carousel-item v-for="i in techs" :key="i" class="rounded-lg">
            <v-row>
              <v-col cols="auto">
                <v-card height="400px" elevation="0" class="tech-card-color rounded-b-xl rounded-t-0">
                  <br>
                  <br>

                  <v-card-title>
                    <v-icon size="small">
                      {{ i.icon }}
                    </v-icon>
                    <br>
                    {{ i.title }}
                  </v-card-title>
                  <v-card-subtitle class="pre-subtitle">
                    {{ i.desc }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
    </v-card>
    <div id="order"></div>
    <v-card color="black">
      <v-container>
        <v-card-item>
          <label class="text-h3 text-lg-h2"><b>Заказать</b></label>
        </v-card-item>
        <br>
        <br>
        <v-row align="center">
          <v-col class="text-center v-col-12 v-col-lg-5">
            <v-img class="mx-auto" width="200px" src="./edit.gif" :lazy-src="true">
            </v-img>
            <v-card-text>
              Если вы уже определились с тем, что вам нужно, то мы предлагаем вам заполнить небольшую анкету, чтобы мы могли лучше понять ваши требования и предоставить вам наиболее точное предложение.
              <br><br>
              Наш брифинг поможет вам структурировать информацию о вашем проекте, включая цели, предпочтения, бюджет и сроки.
            </v-card-text>
            <a href="https://t.me/InstantFormsBot/form?startapp=9bd3f17b-1765-4740-8e3c-400428217522&startApp=9bd3f17b-1765-4740-8e3c-400428217522">
              <v-btn
                  rounded="xl"
                  color="#4F12FD"
                  prepend-icon="mdi-open-in-new"
              >
                Заполнить брифинг
              </v-btn>
            </a>
          </v-col>
          <v-spacer></v-spacer>
          <label class="text-h3 text-lg-h4 text-center v-col-12 v-col-lg-1"><b>Или</b></label>
          <v-spacer></v-spacer>
          <v-col class="text-center v-col-12 v-col-lg-5">
            <v-img class="mx-auto" width="200px" src="./AnimatedStickerEmail.gif" :lazy-src="true">
            </v-img>
            <v-card-text>
              Если у вас возникли вопросы или вы еще не до конца определились с тем, что именно вам нужно, не беспокойтесь!
              <br>
              <br>
              Вы всегда можете связаться с нами по электронной почте или через Telegram, чтобы обсудить свои требования и получить консультацию.
            </v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                    v-if="hidden_email"
                    @click="showEmail()"
                    rounded="xl"
                    color="white"
                    prepend-icon="mdi-eye-outline"
                >
                  Показать e-mail
                </v-btn>
                <div v-else>
                  <v-btn
                      @click="dialog = true"
                      color="white"
                      prepend-icon="mdi-content-copy"
                  >
                    Копировать
                  </v-btn>
                  <br>
                  <p class="mt-3">{{ email }}</p>
                  <v-dialog
                      v-model="dialog"
                      width="auto"
                  >
                    <v-card
                        max-width="400"
                        text="Почта успешно скопирована."
                        title="Готово!"
                    >
                      <template v-slot:actions>
                        <v-btn
                            class="ms-auto"
                            text="Ok"
                            @click="dialog = false"
                        ></v-btn>
                      </template>
                    </v-card>
                  </v-dialog>
                </div>

              </v-col>
              <v-col cols="auto">
                <v-btn
                    rounded="xl"
                    color="white"
                    prepend-icon="mdi-open-in-new"
                >
                  <a href="https://t.me/ITsmiths" class="text-decoration-none text-black">
                    Telegram
                  </a>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card color="black" id="contact">
      <v-container>
        <v-card-item>
          <label class="text-h3 text-lg-h2"><b>Контакты</b></label>
        </v-card-item>
        <br>
        <v-row justify="center" class="text-left">
          <v-col cols="auto">
            <v-card-item>
              E-mail: {{ email }}
              <br>
              Telegram канал: <a href="https://t.me/SmithTeamCase">https://t.me/SmithTeamCase</a>
            </v-card-item>
          </v-col>
          <v-col cols="auto">
            <v-card-item>
              Telegram (Вадим): <a href="https://t.me/ITsmiths">https://t.me/ITsmiths</a>
              <br>
              Telegram (Дмитрий): <a href="https://t.me/mainbatya">https://t.me/mainbatya</a>
            </v-card-item>
            </v-col>
            <v-col cols="auto">
            <v-card-item>
              GitHub (Дмитрий): <a href="https://github.com/roomdie">https://github.com/roomdie</a>
              <br>
              GitHub (Вадим): <a href="https://github.com/smithreactiven">https://github.com/smithreactiven</a>
            </v-card-item>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col cols="12">
            <v-card color="black" class="text-center">
              <v-card-subtitle>© 2024 Smith Team. All Rights Reserved.</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-card>

  </v-app>
</template>

<script>

export default {
  name: "HomePage",
  data: () => ({
    drawer: false,
    group: null,
    menu_btn_loading: false,
    hidden_email: true,
    dialog: false,
    email: "blsmithit@gmail.com",
    dmitriy_positions: [
      {
        color: "#02F38D",
        text: "⚙️ Tech Lead",
      },
      {
        color: "white",
        text: "🦄 Dev Ops",
      }
    ],
    vadim_positions: [
      {
        color: "#02F38D",
        text: "🗂 Project Manager",
      },
      {
        color: "white",
        text: "🧙‍♂️ Frontend Dev"
      }
    ],
    navbar: [
      {
        id: 0,
        title: "Кейсы",
        icon: "mdi-briefcase-outline",
        href: "#cases",
      },
      {
        id: 1,
        title: "Заказать",
        icon: "mdi-clipboard-check-outline",
        href: "#order",
      },
      {
        id: 2,
        title: "Технологии",
        icon: "mdi-animation",
        href: "#tech",
      },
      {
        id: 3,
        title: "Контакты",
        icon: "mdi-account-box-outline",
        href: "#contact"
      },
    ],
    cases: [
      {
        title: '@JourneysBuilderBot',
        type: "Веб приложение",
        desc: "Создавайте собственные конкурсы, привлекая новую аудиторию.",
        url: 'https://t.me/JourneysBuilderBot',
        interval: "3000",
        slides: ["./nick_1.webp", "./nick_2.webp", "./nick_3.webp",],
        color: "deep-purple-accent-1",
        show_icon: false
      },
      {
        title: '@StarfallEventBot',
        type: "Веб приложение",
        desc: "Организовывайте мероприятия или принимайте участие в них.",
        url: 'https://t.me/StarfallEventBot',
        interval: "4000",
        slides: ["./starfall_1.webp", "./starfall_2.webp", "./starfall_3.webp",],
        color: "blue-accent-2",
        show_icon: false
      },
      {
        title: '@GentlyDropBot',
        type: "Веб приложение",
        desc: "Магазин для покупки кроссовок, одежды и аксессуаров.",
        url: 'https://t.me/GentlyDropBot',
        interval: "5000",
        slides: ["./gently_drop_1.webp", "./gently_drop_2.webp", "./gently_drop_3.webp",],
        color: "blue-grey-darken-4",
        show_icon: false
      },
    ],
    techs: [
      {
        title: "Python",
        icon: "mdi-language-python",
        desc: "Язык программирования, используемый для разработки ботов, сайтов и веб-приложений. Python обладает широкими возможностями и является популярным выбором благодаря своей простоте и эффективности."
      },
      {
        title: "Docker",
        icon: "mdi-docker",
        desc: "Платформа для контейнеризации приложений. Docker позволяет запускать ваши боты, сайты и веб-приложения в изолированных контейнерах, что обеспечивает легкость развертывания и масштабирования."
      },
      {
        title: "Vue.js",
        icon: "mdi-vuejs",
        desc: "Обеспечивает быструю и отзывчивую разработку веб-приложений, а также обладает богатым набором инструментов для создания современных и эффективных пользовательских интерфейсов."
      },
      {
        title: "Nginx",
        icon: "mdi-server",
        desc: "Веб-сервер, используемый для обработки запросов к вашим сайтам и веб-приложениям. Nginx имеет высокую производительность и способен обрабатывать большое количество одновременных подключений."
      },
      {
        title: "PostgreSQL",
        icon: "mdi-database-outline",
        desc: "База данных, хранит данные ваших ботов, сайтов и веб-приложений. PostgreSQL обладает мощными функциями и хорошей производительностью, а также обеспечивает надежность и безопасность данных."
      },
      {
        title: "Yonote",
        icon: "mdi-square-edit-outline",
        desc: "Мощный инструмент для организации и совместной работы. Yonote позволяет создавать и управлять проектами, задачами и документацией, а также облегчает коммуникацию с клиентами и участниками проекта."
      },
      {
        title: "Redis",
        icon: "mdi-checkbox-multiple-blank",
        desc: "Быстрая и масштабируемая база данных, используемая для кэширования данных и повышения производительности приложений."
      },
      {
        title: "GitHub",
        icon: "mdi-github",
        desc: "Популярная система контроля версий, используемая для отслеживания изменений в коде и совместной работы в команде разработчиков."
      },
      {
        title: "Timeweb Cloud",
        icon: "mdi-weather-cloudy",
        desc: "Хостинг для вашего бизнеса. Развивайте цифровые продукты вместе с Timeweb Cloud: приложения, веб-сервисы, онлайн-магазины, игры, что угодно."
      },
    ]
  }),
  mounted() {
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    showNavDrawer() {
      this.drawer = !this.drawer
    },
    showEmail() {
      this.hidden_email = !this.hidden_email
    },
    moveToCasesPage() {
      this.$router.push({ name: 'AllCases'});
    },
    emptyFunc() {

    }
  }
}
</script>

<style scoped>

.overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(5px) !important;
}

.case-card-color {
  background-color: rgba(0, 0, 0, 0.17) !important;
  backdrop-filter: blur(5px) !important;
}

.tech-card-color {
  background-color: rgba(0, 0, 0, 0.40) !important;
  backdrop-filter: blur(5px) !important;
}

.title-gradient {
  background: linear-gradient(90deg, #300EFF 25%, #02F38D 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-gradient {
  background: linear-gradient(to top, #000000, #4F12FD 100%, #000000);
}

.glow {
  text-shadow: 0 0 50px rgba(2, 243, 141, 0.30);
}


.case-gradient {
  background: linear-gradient(to bottom, #000000, #4F12FD 100%, #000000);
}

.smooth-border {
  border-width: 1px;
  border-style: solid;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background:
      linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.55),
          rgba(255, 255, 255, 0.25),
          rgba(255, 255, 255, 0.55),
          rgba(0, 0, 0, 0.55)
      ) 1 1 1 1;

}

.pre-subtitle {
  white-space: pre-line;
  padding-bottom: 10px;
}

</style>