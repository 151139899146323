<template>
  <v-container>
    <v-sheet height="200px" color="black"></v-sheet>
    <v-card color="black" class="text-center" elevation="0">
      <v-card-item class="text-h1">
        404
        <br>
      </v-card-item>
      <v-card-item class="text-h3">
        Страница не найдена.
      </v-card-item>
      <br>
      <v-card-item>
        <a href="/">
          <v-btn
              variant="flat"
              color="#4F12FD"
              rounded="lg"
              size="large"
          >
            Вернуться на главную
          </v-btn>
        </a>
      </v-card-item>
    </v-card>
  </v-container>

</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>

<style scoped>

</style>